<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="订单退款"
        :sub-title="order_no"
        @back="() => $router.go(-1)"
      >
        <a-divider orientation="left">
          订单 {{ orderInfo.order_no }}
        </a-divider>
        <a-row :gutter="16" style="margin-top: 10px;">
          <a-col :md="6" :sm="8" span="4" v-if="order_status === 2">
            <a-button @click="allOrderRefund">整个订单退款</a-button>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span></span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
          </a-col>
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :showPagination="false"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text / 100).toFixed(2) }}
        </div>
        <span slot="img" slot-scope="img">
          <a-avatar
            v-if="img"
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleRefund(record)" v-if="order_status === 3">退款</a>
          </template>
        </span>
      </s-table>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
      <a-modal
        v-model="refund_status_visible"
        v-if="refund_status_visible"
        :visible.sync="refund_status_visible"
        title="退款"
        ok-text="确认"
        cancel-text="取消"
        @ok="select_refund"
      >
        <a-row>
          <a-col :md="12" :sm="24" v-if="goods_id">
            <a-form-item label="数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number :min="1" v-model="refund_count" style="width: 140px"/>
            </a-form-item>
          </a-col>
          <a-col :md="goods_id === 0 ? 24 : 12" :sm="24">
            <a-form-item label="理由" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-model="refund_reason" placeholder="请输入理由"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { doBackApplyRefund, orderDetail } from '@/api/applet_shopmall_order'

export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      order_no: '',
      order_status: 0,
      order_id: '',
      goods_id: 0,
      previewvisible: false,
      previewurl: '',
      refund_count: 1,
      refund_reason: '',
      loading: false,
      orderInfo: Object,
      // 创建窗口控制
      visible: false,
      mdl: {},
      refund_status_visible: false,
      dictionaries: Dictionaries,
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品',
          dataIndex: 'picture',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '商品名称',
          align: 'center',
          dataIndex: 'brief'
        },
        {
          title: '仓库对应名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '单位',
          align: 'center',
          dataIndex: 'unit'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderDetail(this.$route.query.id)
          .then((res) => {
            console.log('-------->>>', res)
            const localData = { entries: res.data.goods_list }
            this.orderInfo = res.data
            return localData
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created () {
    this.order_id = this.$route.query.id
    this.order_status = Number(this.$route.query.status)
    console.log('参数', this.order_id, this.order_status)
  },
  methods: {
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    // 订单退款
    handleRefund (record) {
      if (record) {
        console.log(record)
        this.goods_id = record.id
      }
      this.refund_status_visible = true
    },
    select_refund () {
      if (this.refund_reason === null || this.refund_reason.trim() === '') {
        this.$message.warning('请输入退款理由')
        return
      }
      const param = {
        reason: this.refund_reason,
        order_id: this.order_id
      }
      if (this.goods_id && this.goods_id > 0) {
        if (this.refund_count === null || this.refund_count === undefined) {
          this.$message.warning('请输入数量')
          return
        }
        param.count = this.refund_count
        param.order_goods_id = this.goods_id
      }

      doBackApplyRefund(param).then(({ data }) => {
        console.log(data)
        this.count = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    allOrderRefund () {
      this.handleRefund({ id: 0 })
    }
  }
}
</script>
